<template>
    <div class="container">
      <HeaderAdmin></HeaderAdmin>
      <p class="buffer"></p>
      <section id="page-login">
        <div class="block-top">
          <h1>会議室予約デモシステム</h1>
          <p>Meeting room reservation system</p>
          <h2>管理者ログイン</h2>
        </div>
        <div class="block-bottom">
          <div class="inner">
              <p>ユーザー名</p>
            <input class='input-group' name="username" v-model='username'>
          </div>
          <div class="inner">
              <p>パスワード</p>
            <input class='input-group' name="password" type="password" v-model='password'>
          </div>
          <div class="demo_box">
            <p>ログインID: demouser@dsasademo.co.jp</p>
            <p>パスワード: DemoUser1234</p>
            <br>
            <p>※アプリのデモにはこちらをお使いください</p>
          </div>
          <div class="submit-button">
              <button class="btn-green" @click="post_admin_login">ログイン</button>
              <p v-show="!axios_response" class="error_message">ログインできませんでした</p>
          </div>

        </div>
      </section>
    </div>
</template>

<script>
    import Methods from "@/api/methods";
      export default {
        data() {
          return {
            username:"",
            password:"",
            axios_response:true,
          };
        },
        methods: {
          // 管理者ログイン
          async post_admin_login() {
            let login_statues = await Methods.postLogin(this.username, this.password, 1)
            if (login_statues.data) {
              // ログイン情報を store に保存
              this.$store.dispatch("setLoginInfo", login_statues.data)
              this.$router.push({name:"AdminHome"})
            } else {
              this.axios_response = false;
            }
          },
        }
      };
  </script>

<style>
  .demo_box{
    width: 70%;
    margin: 0 auto; 
  }
  .demo_box p{
    color: red;
    margin: 0;
  }
  
</style>