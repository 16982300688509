<template>
  <div id="app">
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style src="./assets/css/style.css">
</style>

