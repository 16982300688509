<template>
  <div class="container">
    <HeaderAdmin></HeaderAdmin>
    <p class="buffer"></p>
    <div class="title_section">
      <h1>｜利用履歴管理</h1>
      <div class="content">
        <select v-model="filter_value" @change="set_filter">
          <option value="0">フィルターなし</option>
          <option v-for="option in serch_options" v-bind:value="option.value" v-bind:key="option.id">
            {{ option.simbol }}
          </option>
        </select>
      </div>
    </div>
    <div class="description_section">
      <p>■利用履歴を管理します。</p>
    </div>

    <p></p>
    
    <div>
      <div class="logs-block" v-for="log in logs_data">
        <h2 class="line-text">{{log.user_name}} 様</h2>
        <table class="table dec-table" id="log-table">
          <thead> 
            <tr>
              <th>利用履歴</th>
              <th>会議室名</th>
              <th>金額</th>
              <th>ステータス</th>
              <th>管理操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log_data in log.logs" v-bind:class="{ bgGray1: log_data.group_status , bgGray2: !log_data.group_price}">
              <td style="padding-left:20px;text-align: left;">{{label_format_date(log_data.group_label)}}<br>{{label_format_time(log_data.group_label)}}</td>
              <td>{{log_data.room_name}}</td>
              <td>{{log_data.group_price.toLocaleString()}} 円</td>
              <td v-if="log_data.group_price && !log_data.group_status">未反映</td>
              <td v-if="log_data.group_price && log_data.group_status">反映済</td>
              <td v-if="!log_data.group_price">キャンセル</td>
              <td class="td-button">
                <button v-if="log_data.group_price" class="btn-sky" @click="post_log_status(log_data)">ステータス変更</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="total-price">
          <p>合計金額: </p>
          <p>{{log.total.toLocaleString()}} 円</p>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
  import Methods from "@/api/methods";
  export default {
    data() {
      return {
        // ユーザー情報
        logs_data:[],
        logs_data_default:[],
        serch_options:[],
        filter_value:0,
        axios_response:true
      };
    },
    created () {
      this.process()
    },
    methods: {
      // 実行順の設定
      async process() {
        await this.get_logs()
        await this.set_param()
        await this.set_filter()
      },
      // クエリパラメータから情報を取得してセット
      async set_param() {
        this.filter_value = this.$route.query.fil
      },
      // ログデータ一覧を取得
      async get_logs() {
        let response = await Methods.getUserLogs();
        this.logs_data = response.data.user_logs
        this.logs_data_default = response.data.user_logs
        // 検索用にデータを加工
        let id = 0
        let list_obj = {}
        let dup_words = []
        for (const serch_obj of response.data.serch_data) {
          const check = dup_words.find(item => item === serch_obj.label)
          if(!check){
            id +=1
            list_obj = {
              id:id,
              value:serch_obj.label,
              simbol:serch_obj.word,
            }
            this.serch_options.push(list_obj)
            dup_words.push(serch_obj.label)
          }  
        }
      },
      // 利用履歴のステータスを切り替え
      async post_log_status(log_data) {
        let statues = await Methods.postLogStatus(log_data.user_id,log_data.group_label)
        // this.$router.push({name:"AdminLogView", query: {fil: this.filter_value} })
        this.$router.go({path: this.$router.currentRoute.path, force: true});
      },
      // 利用履歴のフィルター
      async set_filter() {
        if(this.filter_value == 0){
          this.logs_data = this.logs_data_default
        } else {
          this.logs_data = this.logs_data_default
          // ユーザー単位のループ
          let filter_data = []
          for (const user_datas of this.logs_data) {
            let filter_logs = []
            // ログをループ処理して、データを更新
            for (const user_log_data of user_datas.logs) {
              if(user_log_data.group_label.includes(this.filter_value) ){
                filter_logs.push(user_log_data)
              }
            }            
            let total = filter_logs.reduce(function(sum, element){
              return sum + element.group_price;
            }, 0);
            filter_data.push({
              "user_id":user_datas.user_id,
              "user_name":user_datas.user_name,
              "logs":filter_logs,
              "total":total,
            })
          }
          this.logs_data = filter_data
        }
        
      },
    }
  };
</script>