import axios from 'axios'
import store from '@/store/index.js'

export default () => {
  return axios.create({
    baseURL: `api/`,
    headers: {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + store.state.auth.login.token,
      'data': {}
    }
  })
}
