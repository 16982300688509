<template>
    <header id="header">
        <div class="head_contents">
            <div class="head_logo">
                <h1>会議室予約デモシステム</h1>
            </div>
            <nav id="head_menu">
              <ul class="header-dropmenu">
                <li><router-link :to="{name:'AdminHome'}">HOME</router-link></li>
                <li><router-link :to="{name:'AdminConferenceView'}">会議室管理</router-link></li>
                <li><router-link :to="{name:'AdminLogView', query:{fil:0}}">利用履歴管理</router-link></li>
                <li><a @click="logout()">ログアウト</a></li>
              </ul>
            </nav>
        </div>
        <div class="demo_block" v-if="is_demo_view" @click="disable_alert">
          <h1>! 注意 !</h1>
          <p>このアプリはデモ用のアプリです。</p>
          <p>データベースは不定期にリセットされます。</p>
        </div>
    </header>
</template>

<script>
  export default {
      data() {
          return {
            is_demo_view:true,
          };
      },
      created(){
      },
      methods: {
        disable_alert(){
            // this.is_demo_view = false
          }
      }
  };
  </script>

<style>
.demo_block{
  padding: 10px;
  text-align: center;
  height: 100px;
  background-color: #ffee00;
  color: red;
}
.demo_block h1,.demo_block p{
  margin: 0;
}
</style>