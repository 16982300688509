<template>
  <div class="container">
    <HeaderAdmin></HeaderAdmin>
    <p class="buffer"></p>
    <div class="title_section">
      <h1>｜会議室管理</h1>
      <div class="content"></div>
    </div>
    <div class="description_section">
      <p>■会議室の登録・情報の編集・削除ができます。</p>
    </div>
    <div class="title_section section_title">
      <h2>登録済み会議室一覧</h2>
      <div class="content"></div>
    </div>
    <table class="table dec-table" id="room-table">
      <thead> 
        <tr>
          <th>会議室名</th>
          <th>料金<br>（1時間単位）</th>
          <th>料金<br>（6時間パック）</th>
          <th>入室コード</th>
          <th>管理操作（編集）</th>
          <th>管理操作（削除）</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="room in room_data">
          <td>{{room.room_name}}</td>
          <td>{{room.price}} 円</td>
          <td>{{room.price_pack}} 円</td>
          <td>{{room.room_code}}</td>
          <td class="td-button">
            <button class="btn-blue" @click="modal_control(2,room)">編集</button>
          </td>
          <td class="td-button">
            <button class="btn-orange" @click="modal_control(3,room)">削除</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="submit-button">
      <button class="btn-sky" @click="modal_control(1,null)">会議室を追加する</button>
    </div>
    <!-- モーダル -->
    <div id="overlay" v-if="modal_condition != 0" style="margin-top: 60px;">
      <!-- 登録モーダル -->
      <div class="modal-content fadeIn" v-if="modal_condition == 1">
        <div class="modal-headline">
          <h1>会議室登録</h1>
          <button class="cansel-button" @click="modal_control(0,null)">閉じる</button>
        </div> 
        <div class="modal-main">
          <div class="content line1">
            <p>会議室名:　</p>
            <input type="text" v-model='room_name'><br>
          </div>
          <div class="content line1">
            <p>料金（1時間単位）:　</p>
            <input type="number" v-model='price'><br>
          </div>
          <div class="content line1">
            <p>料金（6時間パック）:　</p>
            <input type="number" v-model='price_pack'><br>
          </div>
          <div class="content line1">
            <p>入室コード:　</p>
            <input type="text" v-model='room_code'><br>
          </div>
          <div class="submit-button">
            <button class="btn-green" @click="room_add">登録する</button>
            <p class="error_message" v-show="!axios_response">エラー：会議室を登録できませんでした</p>
          </div>
        </div>
      </div>
      <!-- 変更モーダル -->
      <div class="modal-content fadeIn" v-if="modal_condition == 2" style="margin-top: 60px;">
        <div class="modal-headline">
          <h1>会議室編集</h1>
          <button class="cansel-button" @click="modal_control(0)">閉じる</button>
        </div>
        <div class="modal-main">
          <div class="content line1">
            <p>会議室名:　</p>
            <input type="text" v-model='selected_room.room_name'><br>
          </div>
          <div class="content line1">
            <p>料金（1時間単位）:　</p>
            <input type="number" v-model='selected_room.price'><br>
          </div>
          <div class="content line1">
            <p>料金（6時間パック）:　</p>
            <input type="number" v-model='selected_room.price_pack'><br>
          </div>
          <div class="content line1">
            <p>入室コード:　</p>
            <input type="text" v-model='selected_room.room_code'><br>
          </div>
          <div class="submit-button">
            <button class="btn-green">変更する</button>
            <p class="error_message">デモアプリでは、会議室の変更/削除はできません</p>
          </div>
        </div>
      </div>
      <div class="modal-content fadeIn" v-if="modal_condition == 3" style="margin-top: 60px;">
        <div class="modal-headline">
          <h1>会議室削除</h1>
          <button class="cansel-button" @click="modal_control(0)">閉じる</button>
        </div>
        <div class="modal-main">
          <div class="content line1">
            <p>会議室の削除：　削除に同意します</p>
            <input type="checkbox" v-model="check_delete">
          </div>
          <div class="submit-button">
            <button class="btn-green">削除する</button>
            <p class="error_message">デモアプリでは、会議室の変更/削除はできません</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Methods from "@/api/methods";
  import {ValidationObserver, ValidationProvider,localize,extend,} from 'vee-validate'
  export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data() {
      return {
        selected_room:{},
        modal_condition:0,
        // 送信情報
        room_name:"",
        price:"",
        price_pack:"",
        room_code:"",
        // 会議室情報
        room_data:[],
        axios_response:true,
        window_type:0,
        // 編集用データ
        target_id:"",
        target_name:"",
        target_price:"",
        target_price_pack:"",
        target_room_code:"",
        error_message:"",
        // 削除用データ
        check_delete:false,
        delete_room_id:"",
      };
    },
    created () {
      this.get_room()
    },
    methods: {
      // 会議室一覧を取得
      async get_room() {
        let response = await Methods.getRoom();
        this.room_data = response.data.rooms
      },
      // リンクボタン
      async link_home() {
        this.$router.push({name:"AdminHome"})
      },
      async link_userview() {
        this.$router.push({name:"AdminUserView"})
      },
      // 会議室を作成
      async room_add() {
        // 別フォームの情報をマスキング
        this.target_name = "ダミー"
        this.target_price = 100
        this.target_price_pack = 100
        this.target_room_code = "demo"
        // POST処理
        let statues = await Methods.postRoom(
          this.room_name,
          this.price,
          this.price_pack,
          this.room_code
        )
        if (statues) {
          this.reload()
        } else {
          this.target_name = ""
          this.target_price = 0
          this.target_price_pack = 0
          this.target_room_code = ""
          this.axios_response =false
        }
      },
      // 会議室情報を変更
      async room_edit() {
        // 別フォームの情報をマスキング
        this.price = 100
        this.price_pack = 100
        this.room_code = "demo"
        // POST処理
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          let statues = await Methods.postEditRoom(
            this.target_id,
            this.target_price,
            this.target_price_pack,
            this.target_room_code
          )
          if (statues) {
            this.reload()
          } else {
            this.price = 0
            this.price_pack = 0
            this.room_code = ""
            this.axios_response =false
          }
        }
      },
      // ウィンドを切り替え
      async change_window(type,room) {
        this.window_type = type
        if(type == 0){//追加
          this.target_name = ""
        } else if (type == 1){//編集
          this.target_id = room.id
          this.target_name = room.room_name
          this.target_price = room.price
          this.target_price_pack = room.price_pack
          this.target_room_code = room.room_code
        } else if (type == 2) {//削除
          this.target_name = room.room_name
          this.target_id = room.id
        }
      },
      // 会議室を削除
      async room_delete() {
        if(this.check_delete){
          let statues = await Methods.deleteRoom(this.target_id)
          if (statues) {
            this.reload()
          } else {
            this.axios_response =false
          }
        }
      },
      modal_control(condition,data){
        this.modal_condition = condition
        this.selected_room = data
      }
    }
  };
</script>