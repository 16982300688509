
<template>
    <footer id="footer">
        <div class="copyright">copyright© Demo Site 2022 </div>
    </footer>
</template>

<script>
    export default {
        data() {
          return {
          };
        },
    };
</script>