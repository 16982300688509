// 共通変数等格納用
import HeaderAdmin from '../components/HeaderAdmin'
import Footer from '../components/Footer'
export default {
    components: {
        HeaderAdmin,
        Footer,
    },
    data() {
        return {
            site_url: "https://schedulemanager.sasaeru-demoserver.net/#/", //テスト環境
        }
    },
    methods: {
        // 日付を修正
        date_fomat: function(date) {
            let result = date.substr(0, 10);
            return result
        },
        // ラベルを開業
        label_format_date: function(label) {
            let date = label.substr(0,label.indexOf(" "));
            return date
        },
        label_format_time: function(label) {
            let time = label.substr(label.indexOf(" "),20);
            return time
        },
        // 概要を抽出して返す
        trim_text: function(base) {
            let result = base.substr(0, 20);
            result += "…"
            return result
        },
        // 再読み込み
        reload() {
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        },
        // ロード待ち処理の共通関数
        now_loading(){
            this.load_count -=1
            if(this.load_count <= 0){
                this.loading_flag=false 
            }
        },
        // localstrageを破棄
        logout(){
            localStorage.removeItem("sasaeru_booking_manager");
            this.$router.push({name:"AdminLogin"})
            // this.$router.go({path: this.$router.currentRoute.path, force: true});
        },
    },
 }