import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);


Vue.config.productionTip = false


// 共通変数等
import gv from './mixins/globalValiables' 
Vue.mixin(gv)

// ロード表示
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
Vue.use(VueLoaders);

// スライダー
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


// バリデーション関連
import VeeValidate, {Validator} from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja';
Validator.localize('ja', ja);
// フィールド名の日本語表示
Vue.use(VeeValidate, {
  locale: "ja",
  dictionary: {
    ja: {
      attributes: {
        user_id: "利用者",
        email: "メールアドレス",
        password: "パスワード",
        confirm_password: "パスワード",
        start_date: "利用日",
        room_name: "会議室名",
        price: "価格",
        room_code: "入室コード",
      }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
