import Api from './index'

export default {
  // ----------------------------------- 管理者関連 --------------------------------------------------
  /**
   * 管理者登録API
   * @param {string} username //ユーザー名
   * @param {string} password //パスワード
   * @param {number} name //表示名
   * @param {number} mail_1 //メールアドレス１
   * @param {number} mail_2 //メールアドレス１
   * @param {number} mail_3 //メールアドレス１
   * @return {boolean} // HTTPステータス
   */
   postAdminEntry (username, password, name, mail_1, mail_2, mail_3) {
    const item = {
        username: username,
        password: password,
        name:name,
        mail_1:mail_1,
        mail_2:mail_2,
        mail_3:mail_3,
    }
    return Api().post('/admins/entry', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 管理者情報取得API
   * @return {array object} // ユーザー一覧
   */
   getSetting() {
    return Api().get('/admins/setting').then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 管理者情報変更API
   * @param {number} name //表示名
   * @param {number} mail_1 //メールアドレス１
   * @param {number} mail_2 //メールアドレス１
   * @param {number} mail_3 //メールアドレス１
   * @return {boolean} // HTTPステータス
   */
   postSetting (name, mail_1, mail_2, mail_3) {
    const item = {
        name:name,
        mail_1:mail_1,
        mail_2:mail_2,
        mail_3:mail_3,
    }
    return Api().post('/admins/setting', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  // ----------------------------------- スケジュール関連 --------------------------------------------------
  /**
   * スケジュール（コマ）取得API
   * @return {array object} // スケジュール（コマ）一覧
   */
   getScheduleBlock () {
    return Api().get('/schedule/block').then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * スケジュール取得API
   * @param {string} conference_room_id //会議室ID
   * @return {array object} // スケジュール一覧
   */
   getSchedule (conference_room_id) {
    return Api().get(`/schedule/room/${conference_room_id}`).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * (週単位)スケジュール取得API
   * @param {string} conference_room_id //会議室ID
   * @return {array object} // スケジュール一覧
   */
   getScheduleWeekly (conference_room_id) {
    return Api().get(`/schedule/weekly/room/${conference_room_id}`).then(function (response) {
      return response
    }).catch(function (error) {
      console.log(error)
      return false
    });
  },
  /**
   * 対象日のスケジュール一覧取得API
   * @param {string} date //日付
   * @return {array object} // スケジュール一覧
   */
//    getScheduleDate (date) {
//     return Api().get(`/schedule/date/${date}`).then(function (response) {
//         return response
//     }).catch(function (error) {
//         console.log(error)
//         return false
//     });
//   },
  /**
   * スケジュールを削除（グループ単位で削除）
   * @param {string} group_label //予約のグループ
   * @param {string} room_id //会議室ID
   * @return {array object} // スケジュール一覧
   */
   deleteSchedule (group_label, room_id) {
    return Api().get(`/schedule/delete/${group_label}/${room_id}`).then(function (response) {
        return [response]
    }).catch(function (error) {
        console.log(error)
        let status_code = error.message.slice(-3);
        return [false,status_code]
    });
  },
  /**
   * 管理者権限でスケジュールを削除（グループ単位で削除）
   * @param {string} group_label //予約のグループ
   * @param {string} user_id
   * @return {array object} // スケジュール一覧
   */
   deleteScheduleAdmin (group_label, user_id) {
    return Api().get(`/schedule/delete/admin/${group_label}/${user_id}`).then(function (response) {
        return [response]
    }).catch(function (error) {
        console.log(error)
        let status_code = error.message.slice(-3);
        return [false,status_code]
    });
  },
  /**
   * 新規スケジュール登録API
   * @param {string} name //名前
   * @return {boolean} // HTTPステータス
   */
   postSchedule (conference_room_id, start_date, start_time, utilization_time, group_label) {
    const item = {
      conference_room_id: conference_room_id,
      start_date: start_date,
      start_time: start_time,
      utilization_time: utilization_time,
      group_label: group_label,
    }
    return Api().post('/schedule', item).then(function (response) {
        return [response]
    }).catch(function (error) {
      let status_code = error.message.slice(-3);
      return [false,status_code]
    });
  },
  /**
   * 新規スケジュール登録(管理者権限)API
   * @return {boolean} // HTTPステータス
   */
   postScheduleAdmin (user_id, conference_room_id, start_date, start_time, utilization_time, group_label) {
    const item = {
        user_id: user_id,
        conference_room_id: conference_room_id,
        start_date: start_date,
        start_time: start_time,
        utilization_time: utilization_time,
        group_label: group_label,
    }
    return Api().post('/schedule/add/admin', item).then(function (response) {
        return [response]
    }).catch(function (error) {
      let status_code = error.message.slice(-3);
      return [false,status_code]
    });
  },
  // ----------------------------------- ユーザー関連 --------------------------------------------------
  /**
   * ユーザー一覧取得API
   * @return {array object} // ユーザー一覧
   */
   getUsers() {
    return Api().get('/user').then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 仮登録ユーザー情報取得API
   * @param {string} token //トークン
   * @return {array object} // ユーザー一覧
   */
   getDemoUser(token) {
    return Api().get(`/user/demo/${token}`).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 新規ユーザー仮登録API（管理者権限）
   * @param {string} user_name //名前
   * @return {boolean} // HTTPステータス
   */
   postUserDemo (user_name) {
    const item = {
      user_name: user_name,
    }
    return Api().post('/user/demo', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * ユーザー本登録API
   * @param {string} username //
   * @param {string} password //
   * @param {string} token //
   * @return {boolean} // HTTPステータス
   */
   postUserEntry (username, password, token) {
    const item = {
      username: username,
      password: password,
      token: token,
    }
    return Api().post('/user/entry', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * ユーザーを削除
   * @param {string} user_id
   * @return {array object} 
   */
   deleteUser (user_id) {
    return Api().get(`/user/delete/${user_id}`).then(function (response) {
        return [response]
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * ユーザー情報取得API
   * @return {array object} // ユーザー一覧
   */
   getUserSetting() {
    return Api().get('/user/setting').then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * ユーザー情報変更API
   * @param {number} email //メールアドレス
   * @return {boolean} // HTTPステータス
   */
   postUserSetting (email) {
    const item = {
        email:email
    }
    return Api().post('/user/setting', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  // ----------------------------------- 会議室関連 --------------------------------------------------
  /**
   * 会議室一覧取得API
   * @return {array object} // 会議室一覧
   */
   getRoom() {
    return Api().get('/room').then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 会議室登録API
   * @param {string} room_name //名前
   * @param {string} price //単価
   * @param {string} price_pack //単価(6時間パック料金)
   * @param {string} room_code //入室コード
   * @return {boolean} // HTTPステータス
   */
   postRoom (room_name, price, price_pack, room_code) {
    const item = {
      room_name: room_name,
      price: price,
      price_pack: price_pack,
      room_code: room_code,
    }
    return Api().post('/room', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 会議室変更API
   * @param {string} id //ID
   * @param {string} price //単価
   * @param {string} room_code //入室コード
   * @return {boolean} // HTTPステータス
   */
   postEditRoom (id, price, price_pack, room_code) {
    const item = {
      price: price,
      price_pack: price_pack,
      room_code: room_code,
    }
    return Api().post(`/room/edit/${id}`, item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 会議室を削除
   * @param {string} id
   * @return {array object} 
   */
   deleteRoom (id) {
    return Api().get(`/room/delete/${id}`).then(function (response) {
        return [response]
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  // ----------------------------------- 利用履歴関連 --------------------------------------------------
  /**
   * 利用履歴取得API
   * @return {array object} // 利用履歴一覧
   */
   getUserLogs() {
    return Api().get(`/userlog`).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
  /**
   * 利用履歴ステータス変更API
   * @param {string} user_id 
   * @param {string} group_label //対象のグループラベル
   * @return {boolean} // HTTPステータス
   */
   postLogStatus (user_id,group_label) {
    const item = {
      user_id: user_id,
      group_label: group_label,
    }
    return Api().post(`/userlog`, item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
    // ----------------------------------- その他 --------------------------------------------------
  /**
   * ログインAPI
   * @param {string} username //ユーザー名
   * @param {string} password //パスワード
   * @param {string} user_type //ユーザータイプ　1=管理者　2=ユーザー
   * @return {boolean} // HTTPステータス
   */
   postLogin (username, password, user_type) {
    const item = {
        username: username,
        password: password,
        user_type:user_type,
    }
    return Api().post('/login', item).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return false
    });
  },
}