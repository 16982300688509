import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'

import PcPage from '@/components/PcPage.vue'
import AdminLogin from '@/components/AdminLogin'
import AdminHome from '@/components/AdminHome'
import AdminLogView from '@/components/AdminLogView'
import AdminConferenceView from '@/components/AdminConferenceView'
import ServerErrorPage from '@/components/ServerErrorPage'

Vue.use(Router)

const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    // ---------------------- 以下ユーザー向けページ ----------------------
    {
      path: '/sperror',
      name: 'PcPage',
      component: PcPage
    },
    {
      path: '/error',
      name: 'ServerErrorPage',
      component: ServerErrorPage
    },
    // ---------------------- 以下管理者向けページ ----------------------
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: AdminLogin,
      meta:{isPc: true}
    },
    {
      path: '/',
      name: 'AdminHome',
      component: AdminHome,
      meta:{isPc: true, requiresAuthAdmin: true}
    },
    {
      path: '/admin/logview',
      name: 'AdminLogView',
      component: AdminLogView,
      meta:{isPc: true, requiresAuthAdmin: true}
    },
    {
      path: '/admin/conferenceview',
      name: 'AdminConferenceView',
      component: AdminConferenceView,
      meta:{isPc: true, requiresAuthAdmin: true}
    },
  ]
})

router.beforeEach((to, from, next) => {
  // スマホ専用ページ
  if (to.matched.some(record => record.meta.isPc)) {
    var width = window.innerWidth;
    if(width < 800) {
      next('/sperror')
    }
  }
  // 管理者ログインが必要なページ
  if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
    // トークンの確認
    if (store.state.auth.login.token && store.state.auth.login.expire > (new Date()).getTime() && store.state.auth.login.user_type==1){
      next()
    } else {
      next('/admin/login')
    }
  }
  // ログイン不要画面
  else {
    next();
  }
});

export default router
