<template>
  <div class="container">
    <HeaderAdmin></HeaderAdmin>
    <p class="buffer"></p>
    <div class="title_section">
      <h1>｜予約一覧・管理</h1>
      <div class="content">
        <select v-model="conference_room_id" @change="set_conference_room">
          <option value="" disabled selected style="display:none;">会議室を選択してください</option>
          <option v-for="option in room_options" v-bind:value="option.value" v-bind:key="option.id">
            {{ option.simbol }}
          </option>
        </select>
      </div>
    </div>
    <div class="description_section">
      <p v-if="this.schedule_data.length != 0">■カレンダー内の色がついている枠をタップすると予約の確認・削除ができます。</p>
      <p v-if="this.schedule_data.length == 0">■会議室を選択してください</p>
    </div>


    <!-- スケジュール一覧 -->
    <div id="month_schedule">
      <carousel 
        :per-page="1"
        :paginationEnabled="false" 
        :navigation-enabled="true" 
        navigation-prev-label="〈　" 
        navigation-next-label="　〉"
      >
      
        <slide v-for="schedule_obj in schedule_data" v-if="schedule_data.length > 0">
          <table id="scadule" class="table"　border="1" style="border-collapse: collapse" align="center">
            <thead> 
              <tr>
                <th class="fixed01"></th>
                <th  class="fixed01" v-for="schedule_block in schedule_block_data">{{schedule_block.label_view}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in schedule_obj">
                <td class="fixed02">{{data.label}}</td>
                <td v-for="user in data.users" v-bind:style="{backgroundColor: user.name ? user.color:''}" @click="set_schedule_date(data.label,data.index,user)">
                </td>
              </tr>
            </tbody>
          </table>
        </slide>
      </carousel>
    </div>
    <!-- ユーザーラベル -->
    <h2 v-if="this.schedule_data.length > 0">ユーザー一覧</h2>
    <div v-if="this.schedule_data.length > 0" class="color-labels">
      <p v-for="option in user_options"><span v-bind:style="{backgroundColor: option.color, color: option.color}">:　</span> {{option.simbol}}</p>
    </div>
    <!-- モーダル -->
    <div id="overlay" v-if="post_flag != 0">
      <!-- 登録モーダル -->
      <div class="modal-content fadeIn" v-if="post_flag == 2">
        <div class="modal-headline">
          <h1>予約登録</h1>
          <button class="cansel-button" @click="modal_control">閉じる</button>
        </div> 
        <div class="modal-main">
          <div class="content line1">
            <p>利用日:　</p>
            <p>{{selected_date}}</p>  
          </div>
          <div class="content line1">
            <p>利用開始時刻:　</p>
            <p>{{selected_start_time}}</p>
          </div>
          <div class="content line1">
            <p>利用時間:　</p>
            <select v-model="utilization_time">
              <option v-for="option in utilization_time_option" v-bind:value="option.value" v-bind:key="option.id">
                {{ option.simbol }}
              </option>  
            </select>
          </div>
          <div class="content line1">
            <p>利用ユーザー:　</p>
            <select v-model="user_id">
              <option v-for="option in user_options" v-bind:value="option.value" v-bind:key="option.id">
                {{ option.simbol }}
              </option>  
            </select>
          </div>
          <div class="submit-button">
            <button class="btn-green" @click="schedule_add">登録する</button>
            <p class="error_message" v-show="error_message">エラー：{{error_message}}</p>
          </div>
        </div>
      </div>
      <!-- 削除モーダル -->
      <div class="modal-content fadeIn" v-if="post_flag == 1">
        <div class="modal-headline">
          <h1>予約者情報</h1>
          <button class="cansel-button" @click="modal_control">閉じる</button>
        </div>
        <div class="modal-main">
          <div class="content line1">
            <p>予約者：{{selected_username}}</p>
          </div>
          <div class="content line1">
            <p>予約時間：{{selected_group_label}}</p>
          </div>
          <div class="content line1">
            <p>予約の削除：　削除に同意します</p>
            <input type="checkbox" v-model="check_delete">
          </div>
          <div class="submit-button">
            <button class="btn-green" @click="remove_schedule">削除する</button>
            <p class="error_message" v-show="!error_message_remove">エラー：スケジュールを削除できませんでした。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Methods from "@/api/methods";
  import {ValidationObserver, ValidationProvider,localize,extend,} from 'vee-validate'
  export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data() {
      return {
        select_month:"1",
        bookig_user_name:"",
        schedule_data:[],
        schedule_data_next:[],
        schedule_block_data:[],
        // リスト用オプション
        user_options:[],
        room_options:[],
        conference_room_id:"",
        axios_response:true,
        // 予約削除用
        post_flag:0,
        selected_userid:"",
        selected_username:"",
        selected_date:"",
        selected_start_time:"",
        check_delete:false,
        selected_group_label:"",
        error_message_remove:true,
        // 予約登録用
        user_id:"",
        selected_post_date:"",
        selected_post_start_time:"",
        utilization_time:"",
        utilization_time_option:[
          {id:"1",simbol:"1時間",value:"2"},
          {id:"2",simbol:"2時間",value:"4"},
          {id:"3",simbol:"3時間",value:"6"},
          {id:"4",simbol:"6時間パック",value:"12"},
        ],
        error_message:"",
      };
    },
    created () {
      this.get_schedule_block()
      this.get_room()
      this.get_users()
    },
    methods: {
      // スケジュール（基準）を取得
      async get_schedule_block() {
        let response = await Methods.getScheduleBlock();
        console.log(response)
        if(response){
          this.schedule_block_data = response.data.schedules
        } else {
          this.$router.push({name:'ServerErrorPage'});
        }
      },
      // ユーザーオプションを取得
      async get_users() {
        let response = await Methods.getUsers();
        let id = 0
        let list_obj = {}
        for (const index in response.data.users) {
          id +=1
          list_obj = {
            id:id,
            value:response.data.users[index].id,
            simbol:response.data.users[index].name,
            color:response.data.users[index].color,
          }
          this.user_options.push(list_obj)
        }
        // リストの初期値を設定
        this.user_id = this.user_options[0].value
        this.utilization_time = this.utilization_time_option[0].value
      },
      // ルームオプションを取得
      async get_room() {
        let response = await Methods.getRoom();
        let id = 0
        let list_obj = {}
        for (const index in response.data.rooms) {
          id +=1
          list_obj = {
            id:id,
            value:response.data.rooms[index].id,
            simbol:response.data.rooms[index].room_name,
            price:response.data.rooms[index].price,
          }
          this.room_options.push(list_obj)
        }
      },
      // 予約者名を表示
      async apply_name(name) {
        this.bookig_user_name = name
      },
      // 会議室選択でスケジュールを更新
      async set_conference_room() {
        let response = await Methods.getSchedule(this.conference_room_id);
        this.schedule_data = response.data.schedules
      },
      // 定期処理の動作確認
      async send_testmail() {
        let response = await Methods.getTest();
        if (statues) {
          this.reload()
        } else {
          this.axios_response =false
        }
      },
      // 日付を選択し、ウィンドを操作
      async set_schedule_date(date,index,user) {
        if(user.name){
          this.post_flag = 1
          this.selected_date = date
          this.selected_start_time = user.label
          this.selected_username = user.name
          this.selected_userid = user.user_id
          this.selected_group_label = user.group_label
        } else {
          this.post_flag = 2 
          this.selected_date = date
          this.selected_start_time = this.schedule_block_data[user.block_id - 1].label
          this.selected_post_date = index
          this.selected_post_start_time = this.schedule_block_data[user.block_id - 1].id
        }
      },
      // モーダルを非表示
      async modal_control() {
        this.post_flag = 0
      },
      // スケジュールを追加（マスター権限）
      async schedule_add() {
        this.error_message = ""
        let group_label = `${this.selected_post_date} ${this.schedule_block_data[this.selected_post_start_time - 1].label}`
        // パック料金の場合は、表示を変える
        if(this.utilization_time == 12){
          group_label += ` から 6時間（パック料金）`
        } else {
          group_label += ` から ${this.utilization_time_option[(this.utilization_time/2) - 1].simbol}`

        }
        let statues = await Methods.postScheduleAdmin(
          this.user_id,
          this.conference_room_id,
          this.selected_post_date,
          this.selected_post_start_time,
          this.utilization_time,
          group_label,
        )
        if (statues[0]) {
          this.reload();
        } else {
          if (statues[1] == "403") {
            this.error_message = "予約不可能な時間帯が含まれています"
          }
        }  
      },
      // スケジュールを削除（マスター権限）
      async remove_schedule() {
        if(this.check_delete){
          let statues = await Methods.deleteScheduleAdmin(this.selected_group_label, this.selected_userid)
          if (statues[0]) {
            this.reload();
          } else {
            this.error_message_remove = false
          }
        }
      },
    }
  };
</script>